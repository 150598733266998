.loader {
    width: 28px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #7e4d8b;
    animation: l2 1.5s infinite;
}
@keyframes l2 {
    0%,
    100% {
        transform: translate(-35px);
        box-shadow: 0 0 #f9fafb, 0 0 #008fc3;
    }
    40% {
        transform: translate(35px);
        box-shadow: -15px 0 #f9fafb, -30px 0 #008fc3;
    }
    50% {
        transform: translate(35px);
        box-shadow: 0 0 #f9fafb, 0 0 #008fc3;
    }
    90% {
        transform: translate(-35px);
        box-shadow: 15px 0 #f9fafb, 30px 0 #008fc3;
    }
}
