.loader-container-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.loader-3d-animation {
    position: relative;
    width: 50px;
    height: 50px;
    transform: translateZ(0);
    transform-style: preserve-3d;
    animation: spin-animation 3s infinite linear;
}

.face {
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: 70px;
    opacity: 0.8;
}

.front {
    transform: translateZ(25px);
}
.back {
    transform: rotateY(180deg) translateZ(25px);
}
.left {
    transform: rotateY(-90deg) translateZ(25px);
}
.right {
    transform: rotateY(90deg) translateZ(25px);
}
.top {
    transform: rotateX(90deg) translateZ(25px);
}
.bottom {
    transform: rotateX(-90deg) translateZ(25px);
}

@keyframes spin-animation {
    0% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    12% {
        transform: rotateX(45deg) rotateY(45deg) rotateZ(45deg);
    }
    25% {
        transform: rotateX(90deg) rotateY(90deg) rotateZ(90deg);
    }
    50% {
        transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
    }
    75% {
        transform: rotateX(270deg) rotateY(270deg) rotateZ(270deg);
    }
    90% {
        transform: rotateX(310deg) rotateY(310deg) rotateZ(310deg);
    }

    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}
